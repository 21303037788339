import React, { Component } from 'react';
import $ from 'jquery';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

class Footer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			api_base_url : window.$apiBaseUrl,
			image_base_url : window.$imageBaseUrl,
			facebook_link : '',
			twitter_link : '',
			instagram_link : '',
			youtube_link : '',
			footer_contact : '',
			copyright_text : '',
		}
		this.getSettingsContent = this.getSettingsContent.bind(this);
	}
	componentDidMount() {
		this.getSettingsContent();
	}
	getSettingsContent(){
		var formData = new FormData();
		axios.post(''+this.state.api_base_url+'settings/read.php', formData)
		.then(res => {
			this.setState({facebook_link : res.data.social_link1.meta_value});
			this.setState({twitter_link : res.data.social_link2.meta_value});
			this.setState({instagram_link : res.data.social_link3.meta_value});
			this.setState({youtube_link : res.data.social_link4.meta_value});
			this.setState({footer_contact : res.data.footer_contact_address.meta_value});
			this.setState({copyright_text : res.data.copyright_text.meta_value});
		}).catch(error => {
			console.log(error);
		});
	}
	render() {
		return (
		<React.Fragment>
			<footer className="bg-black pt-5">
				<div className="container">
					<div className="row">
						<div className="col-lg-3 col-md-12 mb-4">
							<div className="mb-3 text-center mt-4 text-lg-start">
								<h1 className="title text-white">Site map</h1>
								<div className="border-small bg-white"></div>
							</div>
							<ul className="p-0 m-0 text-center text-lg-start list-unstyled link">
								<li><Link to="/about">About</Link></li>
								<li><Link to="/influencers">Talent</Link></li>
								<li><Link to="/careers">Careers</Link></li>
								<li><Link to="/contact">Contact</Link></li>
							</ul>
						</div>
						<div className="col-lg-3 col-md-12 mb-4">
						   <div className="mb-3 text-center mt-4 text-lg-start">
								<h1 className="title text-white">Follow</h1>

								<div className="border-small bg-white"></div>
						   </div>
						   <ul className="p-0 m-0 text-center text-lg-start list-unstyled link socail-link">
								{/*<a href={this.state.facebook_link} className="me-3" target="_blank"><i className="fab fa-facebook-square"></i></a>*/}
								{/*<a href={this.state.twitter_link} className="me-3" target="_blank"><i className="fab fa-twitter"></i></a>*/}
								<a href={this.state.twitter_link} className="me-3" target="_blank"><i className="fab fa-tiktok"></i></a>
								<a href={this.state.instagram_link} className="me-3" target="_blank"><i className="fab fa-instagram"></i></a>
						   </ul>
						</div>
						<div className="col-lg-3 col-md-12 mb-4">
							<div className="mb-3 mt-4 text-center text-lg-start">
								<h1 className="title text-white">Contact</h1>

								<div className="border-small bg-white"></div>
							</div>
							<ul className="p-0 m-0 text-center text-lg-start list-unstyled link">
								<li>
									{ ReactHtmlParser(this.state.footer_contact)}
								</li>
							</ul>
						</div>
					</div>
				</div>
			</footer>
			<div className="copyright py-3">
				<div className="container">
					<div className="row">
						<div className="text-center">
							<p className="text-white m-0 small">
								{ ReactHtmlParser(this.state.copyright_text)}
							</p>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
		);
	}
}

export default Footer;