import React, { Component } from 'react';
import $ from 'jquery';
import axios from 'axios';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

class Careers extends Component {
	constructor(props) {
		super(props);
		this.state = {
			api_base_url : window.$apiBaseUrl,
			image_base_url : window.$imageBaseUrl,
			careers_contents : [],
			isOpen : false,
			job_id : '',
			applicant_name : '',
			applicant_email : '',
			applicant_phone : '',
			applicant_message : '',
		}
		this.getCareerContent = this.getCareerContent.bind(this);
		this.convert = this.convert.bind(this);
		this.openModal = this.openModal.bind(this);
		this.hideModal = this.hideModal.bind(this);
		this.saveAndClose  = this.saveAndClose.bind(this);
		this.validateApplyForm  = this.validateApplyForm.bind(this);
		this.change = this.change.bind(this);
	}
	componentDidMount() {
		document.title = "Spotlight Management | Work With Us";
		window.scrollTo(0, 0);
		this.getCareerContent();
	}
	change(e) {
		this.setState({[e.target.name]: e.target.value});
	}
	getCareerContent(){
		var formData = new FormData();
		axios.post(''+this.state.api_base_url+'careers/read.php', formData)
		.then(res => {
			var response = res.data.resp
			if(response === '1'){
				var slider_obj = res.data.data;
				let {careers_contents} = this.state;
				careers_contents = slider_obj;
				this.setState({careers_contents});
			}
			console.log(this.state.careers_contents);
		}).catch(error => {
			console.log(error);
		});
	}
	convert(str) {
		if(str != ''){
			var date = new Date(str),
				mnth = ("0" + (date.getMonth()+1)).slice(-2),
				day  = ("0" + date.getDate()).slice(-2);
			var currentHours = ("0" + date.getHours()).slice(-2),
				currentMinute= ("0" + date.getMinutes()).slice(-2),
				currentSecond= ("0" + date.getSeconds()).slice(-2);
			var full_date = [ day, mnth, date.getFullYear()].join("-");
			//var full_time = [ currentHours , currentMinute,  currentSecond ].join(":");
            //return [full_date, full_time].join(" ");
            return full_date;
		}else{
            //return '0000-00-00 00:00:00';
            return '0000-00-00';
		}
	}
	openModal(e){
		$('.email_resp').removeClass('alert-success').html('');
		this.setState({ isOpen: true });
		var job_id = e.target.getAttribute('job_id');
		this.setState({ job_id: job_id });
	}
	hideModal(){
		this.setState({ isOpen: false });
	}
	saveAndClose(){
		if(this.validateApplyForm()){
			var job_id = this.state.job_id; 
			var applicant_name = this.state.applicant_name; 
			var applicant_email = this.state.applicant_email; 
			var applicant_phone = this.state.applicant_phone; 
			var applicant_message = this.state.applicant_message; 

			var formData = new FormData();
			formData.append('job_id',job_id);
			formData.append('applicant_name',applicant_name);
			formData.append('applicant_email',applicant_email);
			formData.append('applicant_phone',applicant_phone);
			formData.append('applicant_message',applicant_message);
			axios.post(''+this.state.api_base_url+'careers/send_email.php', formData)
			.then(res => {
				var response = res.data.resp;
				if(response === 1){
					this.setState({ job_id: '' });
					this.setState({ applicant_name: '' });
					this.setState({ applicant_email: '' });
					this.setState({ applicant_phone: '' });
					this.setState({ applicant_message: '' });
					$('.email_resp').addClass('alert-success').html(res.data.msg);
					this.hideModal();
				}
			}).catch(error => {
				console.log(error);
			});
		}
	}
	validateApplyForm(){
		var valid = true;
		var applicant_name = this.state.applicant_name; 
		var applicant_email = this.state.applicant_email; 
		var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
		var applicant_phone = this.state.applicant_phone; 
		var applicant_message = this.state.applicant_message; 

		if(applicant_name == ""){
			$('#applicant_name').css('border','2px solid red');
			valid = false;
		}else{
			$('#applicant_name').css('border','');
		}

		if(applicant_email == ""){
			$('#applicant_email').css('border','2px solid red');
			valid = false;
		}else if(!pattern.test(applicant_email)){
			$('#applicant_email').css('border','2px solid red');
			valid = false;
		}else{
			$('#applicant_email').css('border','');
		}

		if(applicant_phone == ""){
			$('#applicant_phone').css('border','2px solid red');
			valid = false;
		}else{
			$('#applicant_phone').css('border','');
		}

		if(applicant_message == ""){
			$('#applicant_message').css('border','2px solid red');
			valid = false;
		}else{
			$('#applicant_message').css('border','');
		}

		return valid;

	}
	render() {
		let j=1;
        let careers_contents = this.state.careers_contents.map((careers_content,j)=>{
			return (
				<React.Fragment>
					<div className="col-lg-12">
						<div className="border-dashed"></div>
					</div>
					<div className="col-lg-12">
						<div className="d-flex justify-content-between mb-4">
							<div className="">
								<h3 className="career-title">{careers_content.job_name}</h3>
							</div>
							<div className="">
							<h3 className="career-title"><span className="text-yellow"><i className="fas fa-calendar-alt"></i></span>
								{this.convert(careers_content.created_at)}</h3>
							</div>
						</div>
						<div className="career-text-black">
							{ ReactHtmlParser(careers_content.job_description)}
						</div>
						<div className=" py-5">
							<a href="javascript:void(0);" job_id={careers_content.id} className="btn-black" onClick={this.openModal}>Apply</a>
						</div>
					</div>
				</React.Fragment>
			)
		});
		return (
		<React.Fragment>
			{/* Start Banner */}
			<section className="banner sub-banner">
			  <img src="img/careers-bg.jpg" className="img-fluid" alt="" />
			  <div className="overlay"></div>
			  <div className="heading">
				 <h3 className="subbanner-subtitle d-none d-lg-block">Spotlight</h3>
				 <h3 className="subbanner-subtitle d-block d-lg-none">Home.</h3>
				 <h2 className="subbanner-title">careers</h2>
			  </div>
			</section>
			{/* End Banner */}

			{/* Our Clients */}
			<section className="">
			  <div className="container mt-lg-5">
				 <div className="row mt-lg-5">
					<div className="col-xl-12 col-lg-12 mb-lg-5">
					   <div className="text-lg-end text-center mt-lg-5 mt-4 mt-lg-0 mb-lg-5 mb-4">
						  <h1 className="title">we are hiring</h1>
						  <div className="border-small"></div>
						  <div className="career-text-black mt-4">
						  <p className="text-black">We are actively seeking ambitious and forward-thinking Talent Managers and Brand Partnerships Managers to join us at Spotlight.
If you want to be part of our incredible team and make all the magic happen for our rostered talent then we want to hear from you!
Email us at <a className="text-blue" href="mailto:info@spotlight-management.com">info@spotlight-management.com</a> anytime or apply to a specific job below.
Please note due to the large volume of applications received, we are unable to respond to all career enquiries. We will be in touch within ten working days if we wish to proceed further with your application.</p>
						  </div>
					   </div>
					</div>
				 </div>
			  </div>
			  <div className="meet-our-talent">
				 <div className="container">
					<div className="row">
					   {careers_contents}
					</div>
				 </div>
			  </div>

			</section>
			{/* End our clients */}
			<Modal show={this.state.isOpen} onHide={this.hideModal}>
				<Modal.Header closeButton>
					<Modal.Title>Apply For Job</Modal.Title>
				</Modal.Header>
				<Modal.Body>
				<form>
					<div className="form-group">
						<label htmlFor="applicant_name" className="col-form-label">Applicant Name:</label>
						<input type="text" className="form-control" name="applicant_name" id="applicant_name" value={this.state.applicant_name} onChange={this.change} />
					</div>
					<div className="form-group">
						<label htmlFor="applicant_email" className="col-form-label">Applicant Email:</label>
						<input type="text" className="form-control" name="applicant_email" id="applicant_email" value={this.state.applicant_email} onChange={this.change} />
					</div>
					<div className="form-group">
						<label htmlFor="applicant_phone" className="col-form-label">Applicant Phone:</label>
						<input type="text" className="form-control" name="applicant_phone" id="applicant_phone" value={this.state.applicant_phone} onChange={this.change} />
					</div>
					<div className="form-group">
						<label htmlFor="applicant_message" className="col-form-label">Message:</label>
						<textarea className="form-control" name="applicant_message" id="applicant_message" value={this.state.applicant_message} onChange={this.change}></textarea>
						<input type="hidden" name="job_id" value={this.state.job_id} />
					</div>
				</form>
				</Modal.Body>
				<Modal.Footer>
					<div className="alert email_resp"></div>
					<Button variant="primary" onClick={this.saveAndClose}>
						Apply
					</Button>
			</Modal.Footer>
			</Modal>
		</React.Fragment>
		);
	}
}

export default Careers;