import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom'
import Home from '../Home/Home';
import About from '../About/About';
import Influencers from '../Influencers/Influencers';
import Careers from '../Careers/Careers';
import Contact from '../Contact/Contact';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-198841519-1');

class MainComponent extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		ReactGA.pageview(window.location.pathname + window.location.search);
		return (
			<div>
				<Header />
					<Switch>
						<Route exact path='/' component={Home} />
						<Route path='/about' component={About} />
						<Route path='/influencers' component={Influencers} />
						<Route path='/careers' component={Careers} />
						<Route path='/contact' component={Contact} />
					 </Switch>
				 <Footer />
			</div>
		);
	}
}

export default withRouter(MainComponent);
