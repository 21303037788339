import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Typed from 'react-typed';
import ReactPlayer from 'react-player/lazy'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
var talent_slider_settings = {
	dots: true,
    autoplay: false,
    infinite: true,
    slidesToShow: 4,
    slideswToScroll: 4,
    arrows: false,
    responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        }

    ]
};
var client_slider_settings = {
    autoplay: false,
    infinite: true,
    dots: true,
    slidesToShow: 3,
    slideswToScroll: 3,
    arrows: true,
    responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }

    ]

};
class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			api_base_url : window.$apiBaseUrl,
			image_base_url : window.$imageBaseUrl,
			influencers_list : [],
			client_sliders :[],
			brand_logos :[],
			home_page_about_content : '',
			mission_content:[],
			offset : 0,
			limit : 8,
			about_page_first_iamge : '',
			about_page_second_iamge : '',
			desktop_video_sound : true,
			mobile_video_sound : true,
			desktop_sound_icon : 'sound_off.png',
			mobile_sound_icon : 'sound_off.png',
		}
		this.getMissionContent = this.getMissionContent.bind(this);
		this.getTalentSlider = this.getTalentSlider.bind(this);
		this.getClientSlider = this.getClientSlider.bind(this);
		this.getBrandLogos = this.getBrandLogos.bind(this);
		this.getHomePageAboutContent = this.getHomePageAboutContent.bind(this);
		this.getAboutPageAboutImage = this.getAboutPageAboutImage.bind(this);	
		this.videoEnded = this.videoEnded.bind(this);	
		this.DesktopVideoSound = this.DesktopVideoSound.bind(this);	
		this.MobileVideoSound = this.MobileVideoSound.bind(this);	
	}

	componentDidMount() {
		document.title = "Spotlight Management | Home";
		window.scrollTo(0, 0);
		this.getMissionContent();
		this.getTalentSlider();
		this.getClientSlider();
		this.getBrandLogos();
		this.getHomePageAboutContent();
		this.getAboutPageAboutImage();
	}

	DesktopVideoSound(){
		if(this.state.desktop_video_sound === true){
			this.setState({desktop_sound_icon: 'sound_on.png'});
			this.setState({desktop_video_sound: false});
		}else{
			this.setState({desktop_sound_icon: 'sound_off.png'});
			this.setState({desktop_video_sound: true});
		}
	}

	MobileVideoSound(){
		if(this.state.mobile_video_sound === true){
			this.setState({mobile_sound_icon: 'sound_on.png'});
			this.setState({mobile_video_sound: false});
		}else{
			this.setState({mobile_sound_icon: 'sound_off.png'});
			this.setState({mobile_video_sound: true});
		}
	}

	videoEnded(){
		this.myRef.scrollIntoView()
	}

	getMissionContent(){
		var formData = new FormData();
		axios.post(''+this.state.api_base_url+'mission/read.php', formData)
		.then(res => {
			this.setState({mission_content: res.data});
		}).catch(error => {
			console.log(error);
		});
	}

	getAboutPageAboutImage(){
		var formData = new FormData();
		axios.post(''+this.state.api_base_url+'about/read_image.php', formData)
		.then(res => {
			this.setState({about_page_first_iamge: res.data.first_image.page_image});
			this.setState({about_page_second_iamge: res.data.second_image.page_image});
		}).catch(error => {
			console.log(error);
		});
	}

	getTalentSlider(){
		var limit = this.state.limit;
		var offset = this.state.offset;
		var formData = new FormData();
		formData.append('limit',limit);
		formData.append('offset',offset);
		axios.post(''+this.state.api_base_url+'creator/read_featured.php', formData)
		.then(res => {
			var response = res.data.resp
			if(response === '1'){
				var influencers_obj = res.data.data;
				let {influencers_list} = this.state;
				influencers_list.push.apply(influencers_list, influencers_obj);
				this.setState({influencers_list});
				var new_offset = limit+offset;
				this.setState({ offset: new_offset });
			}
			
		}).catch(error => {
			console.log(error);
		});
	}

	getClientSlider(){
		var formData = new FormData();
		axios.post(''+this.state.api_base_url+'testimonial/read.php', formData)
		.then(res => {
			var response = res.data.resp
			if(response === '1'){
				var slider_obj = res.data.data;
				let {client_sliders} = this.state;
				client_sliders = slider_obj;
				this.setState({client_sliders});
			}
		}).catch(error => {
			console.log(error);
		});
	}

	getBrandLogos(){
		var formData = new FormData();
		axios.post(''+this.state.api_base_url+'brands_logo/read.php', formData)
		.then(res => {
			var response = res.data.resp
			if(response === '1'){
				var slider_obj = res.data.data;
				let {brand_logos} = this.state;
				brand_logos = slider_obj;
				this.setState({brand_logos});
			}
		}).catch(error => {
			console.log(error);
		});
	}

	getHomePageAboutContent(){
		var formData = new FormData();
		formData.append('page_type','home_page');
		axios.post(''+this.state.api_base_url+'about/read.php', formData)
		.then(res => {
			this.setState({home_page_about_content: res.data});
		}).catch(error => {
			console.log(error);
		});
	}
	
	render() {
		let i=1;
        let client_sliders = this.state.client_sliders.map((client_slider,i)=>{
			return (
				<div className="">
					<div className="clients-box mb-lg-4">
						<div className="mb-5 mt-4">
							<h2 className="card-title">{client_slider.client_name}</h2>
							<h3 className="sub-title mt-3">{client_slider.company_name}</h3>
						</div>
						<div className="clients-content">
							<div className="d-flex">
								<div className="me-2">
								<img src="img/e1.png" className="img-fluid" alt="" />
								</div>
								<div className="">
								<p className="comment">{client_slider.testimonial_content}</p>
								</div>
								<div className="align-items-end d-flex text-end">
								<img src="img/e1.png" className="img-fluid" alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		});
		let j=1;
        let brand_logos = this.state.brand_logos.map((brand_logo,j)=>{
			return (
				<div class="col-lg-2 col-6 text-center">
					<img src={this.state.image_base_url+'brands-logo/'+brand_logo.logo_name} class="img-clint" alt=""></img>
				</div>
			)
		});
		let d=1;
        let influencers_contents_desktop = this.state.influencers_list.map((influencers,d)=>{
			var facebook_link = [];
			var twitter_link = [];
			var instagram_link = [];
			if(influencers.creator_facebook_link!==""){
				facebook_link.push(
					<a href={influencers.creator_facebook_link} target="_blank"><i className="fab fa-youtube"></i></a>
				);
			}
			if(influencers.creator_twitter_link!==""){
				twitter_link.push(
					<a href={influencers.creator_twitter_link} target="_blank"><i className="fab fa-tiktok"></i></a>
				);
			}
			if(influencers.creator_instagram_link!==""){
				instagram_link.push(
					<a href={influencers.creator_instagram_link} target="_blank"><i className="fab fa-instagram"></i></a>
				);
			}
			return (
				<React.Fragment>
					<div className="col-lg-3 mb-3">
						<div className="talent-box mb-3">
							<img src={this.state.image_base_url+'creator/'+influencers.creator_image} className="img-fluid" alt="" />
							<div className="content-overlay"></div>
							<div className="talent-content fadeIn-bottom">
								<h3 className="name">{influencers.creator_name}</h3>
								<div className="social-icon mt-4">
									{facebook_link}
									{twitter_link}
									{instagram_link}
								</div>
							</div>
						</div>
					</div>
				</React.Fragment>
			)
		});
		let m=1;
        let influencers_contents_mobile = this.state.influencers_list.map((influencers,m)=>{
			var facebook_link = [];
			var twitter_link = [];
			var instagram_link = [];
			if(influencers.creator_facebook_link!==""){
				facebook_link.push(
					<a href={influencers.creator_facebook_link} target="_blank"><i className="fab fa-youtube"></i></a>
				);
			}
			if(influencers.creator_twitter_link!==""){
				twitter_link.push(
					<a href={influencers.creator_twitter_link} target="_blank"><i className="fab fa-tiktok"></i></a>
				);
			}
			if(influencers.creator_instagram_link!==""){
				instagram_link.push(
					<a href={influencers.creator_instagram_link} target="_blank"><i className="fab fa-instagram"></i></a>
				);
			}
			return (
				<React.Fragment>
					<div className="col-6 col-md-6 mb-2 pe-1">
						<div className="talent-box">
							<img src={this.state.image_base_url+'creator/'+influencers.creator_image} className="img-fluid" alt="" />
							<div className="content-overlay"></div>
							<div className="talent-content fadeIn-bottom">
								<h3 className="name">{influencers.creator_name}</h3>
								<div className="social-icon mt-4">
									{facebook_link}
									{twitter_link}
									{instagram_link}
								</div>
							</div>
						</div>
					</div>
				</React.Fragment>
			)
		});
		var mission_first_content = this.state.mission_content.first_mission;
		var mission_second_content = this.state.mission_content.second_mission;
		var mission_third_content = this.state.mission_content.third_mission;
		var mission_fourth_content = this.state.mission_content.fourth_mission;
		let first_mission_content;
		let first_mission_image;
		if(mission_first_content){
			first_mission_content = mission_first_content.mission_content;
			first_mission_image = mission_first_content.mission_image;
		}
		let second_mission_content;
		let second_mission_image;
		if(mission_second_content){
			second_mission_content = mission_second_content.mission_content;
			second_mission_image = mission_second_content.mission_image;
		}
		let third_mission_content;
		let third_mission_image;
		if(mission_third_content){
			third_mission_content = mission_third_content.mission_content;
			third_mission_image = mission_third_content.mission_image;
		}
		let fourth_mission_content;
		let fourth_mission_image;
		if(mission_fourth_content){
			fourth_mission_content = mission_fourth_content.mission_content;
			fourth_mission_image = mission_fourth_content.mission_image;
		}
		
		return (
			<React.Fragment>
				{/* Start Banner */} 
			   <section className="banner">
			   		{/* <iframe className="d-none d-lg-block"src="https://www.youtube.com/embed/5Z1JTo_NOmg?autoplay=1&mute=1&loop=1&rel=0&showinfo=0&color=white&iv_load_policy=3&playlist=5Z1JTo_NOmg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
					<iframe className="d-block d-lg-none mob-video" src="https://www.youtube.com/embed/wEVLXmJt5gM?autoplay=1&mute=1&loop=1&rel=0&showinfo=0&color=white&iv_load_policy=3&playlist=wEVLXmJt5gM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */} 
					<ReactPlayer playsinline={true} url='https://spotlightvideohome.s3.eu-west-2.amazonaws.com/Spotlight+Management+_Final.mp4' muted={this.state.desktop_video_sound} playing={true} className="d-none d-lg-block desk-video" onEnded={this.videoEnded} />
					
					<ReactPlayer playsinline={true} url='https://spotlightvideohome.s3.eu-west-2.amazonaws.com/Spotlight+Management+_Final_Mobile.mp4' muted={this.state.mobile_video_sound} playing={true} className="d-block d-lg-none mob-video" onEnded={this.videoEnded} />
					
				  <div className="overlay"></div>
			   </section>
			   <img src={"img/"+this.state.desktop_sound_icon} className="img-fluid desktop_sound_icon" alt="desktpop" onClick={this.DesktopVideoSound}/>
			   <img src={"img/"+this.state.mobile_sound_icon} className="img-fluid mobile_sound_icon" alt="mobile" onClick={this.MobileVideoSound}/>
			   {/*  End Banner */} 
			   {/*  Start About Us  */} 
				<section className="about" ref={ (ref) => this.myRef=ref }>
					<div className="container">
						<div className="row">
							<div className="col-lg-6 offset-lg-2 col-sm-12">
								<div className="text-lg-end text-center mb-5">
									<h1 className="title-about">
										<span className="text-yellow" id="typed-strings">Talent With</span> <Typed
                    strings={['INTENT','PASSION','DIFFERENCE']} typeSpeed={100} backSpeed={50} loop showCursor={false}/><br /> 
									</h1>
								</div>
							</div>

							<div className="col-lg-5 offset-lg-2 col-sm-12">
								<img src={this.state.image_base_url+'about/'+this.state.about_page_first_iamge} alt="" className="img-about" />
							</div>
							<div className="col-lg-4 col-sm-12 ">
								<div className="y-box">
									<h1>About Us</h1>
									{ ReactHtmlParser(this.state.home_page_about_content) }
									<p className="text-center">
										<Link to="/about" className="text-more">MORE</Link>
									</p>

								</div>
							</div>
						</div>
					</div>
				</section>

				{/*  End About Us  */}
			   {/*  Mission  
			   <section className="mission py-5">
				  <div className="container mb-lg-5">
					 <div className="row">
						<div className="col-xl-12 col-lg-12 mb-lg-5">
						   <div className="text-lg-end text-center mb-5">
							  <h1 className="title">Mission</h1>

							  <div className="border-small"></div>
						   </div>
						</div>
						<div className="col-xl-5 col-lg-8 mx-auto mb-3">
						   <div className="mission box1">
								<img src={this.state.image_base_url+'mission/'+first_mission_image} className="img-fluid d-none d-lg-block" alt="" />
								<img src={this.state.image_base_url+'mission/'+first_mission_image} className="img-fluid d-block d-lg-none" alt="" />
								<div className="content-overlay"></div>
								<div className="m-content fadeIn-left">
									{ ReactHtmlParser(first_mission_content)}
								</div>
						   </div>
						</div>
						<div className="col-xl-7 col-lg-12">
						   <div className="row">
							  <div className="col-xl-7 col-lg-7 d-none d-lg-block mb-3">
								 <div className="mission box2 h-lg-412">
									<img src={this.state.image_base_url+'mission/'+second_mission_image} className="img-fluid" alt="" />
									<div className="content-overlay"></div>
									<div className="m-content a">
										{ ReactHtmlParser(second_mission_content)}
									</div>
								 </div>
							  </div>
							  <div className="col-xl-5 col-lg-5 d-none d-lg-block mb-3">
								 <div className="mission box2">
									<img src={this.state.image_base_url+'mission/'+third_mission_image} className="img-fluid" alt="" />
									<div className="content-overlay"></div>
									<div className="m-content b">
										{ ReactHtmlParser(third_mission_content)}
									</div>
								 </div>
							  </div>
							  <div className="col-xl-12 mb-3">
								 <div className="mission box2">
									<img src={this.state.image_base_url+'mission/'+fourth_mission_image} className="img-fluid" alt="" />
									<div className="content-overlay"></div>
									<div className="m-content">
										{ ReactHtmlParser(fourth_mission_content)}
									</div>
								 </div>
							  </div>
						   </div>
						</div>

					 </div>
				  </div>

			   </section>
			   */} 
			   {/*  End mission  */} 
				{/*  Talent  */} 
				<section class="py-lg-5">
					<div class="container">
						<div class="row">
							<div class="col-xl-12 col-lg-12 mb-lg-5">
							<div class="text-lg-end text-center mb-5">
								<h1 class="title">Talent</h1>

								<div class="border-small"></div>
							</div>
							</div>
						</div>
					</div>
					{/* Desktop  */}
					<div className="meet-our-talent d-none d-lg-block">
						<div className="container">
							<div className="row">
								{influencers_contents_desktop}
								<div className="col-lg-12">
									<div className="text-center py-5">
										<Link to="/influencers"><a href="javascript:void(0);" className="btn-black">More</a></Link>
										<input type="hidden" name="offset" value={this.state.offset} />
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* End Desktop  */}
					{/* Mobile  */}
					<div className="meet-our-talent d-block d-lg-none">
						<div className="container">
							<div className="row">
								{influencers_contents_mobile}
								<div className="col-lg-12">
									<div className="text-center py-5">
										<Link to="/influencers"><a href="javascript:void(0);" className="btn-black">More</a></Link>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* End Mobile */}
				</section>
				{/*End Talent  */} 
			   {/*  Our Testimonial */} 
				<section class="py-lg-5">
					<div class="container mt-5">
						<div class="row mt-5">
							<div class="col-xl-12 col-lg-12 mb-lg-5">
							<div class="text-lg-end text-center mt-5 mt-lg-0 mb-lg-5">
								<h1 class="title">Testimonials</h1>

								<div class="border-small"></div>
							</div>
							</div>
						</div>
					</div>
					<div class="our-clients">
						<div class="container-fluid p-0">
							<div class="row">
								<div class="col-xl-12">
									<div class="testimonials pt-3 m-0">
										<Slider {...client_slider_settings}>
											{client_sliders}
										</Slider>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/*  End Testimonial */} 
				 {/*  Our Clients  */} 
				 <section class="brand">
					<div class="container">
						<div class="row">
							<div class="col-xl-12 col-lg-12 mb-lg-5">
							<div class="text-lg-end text-center mt-5 mt-lg-0 mb-lg-5">
								<h1 class="title">Brands We Have Worked With</h1>

								<div class="border-small"></div>
							</div>
							</div>
						</div>
						<div class="row">
							<div class="col-lg-10 offset-lg-1 col-12">
								<div class="row">
								  	{brand_logos}
								</div>
            				</div>
         				</div>
      				</div>
   				</section>
			   {/*  End our clients  */} 
			   {/*  About 
			   <section className="bg-dark py-5">
				  <div className="container">
					 <div className="row">
						<div className="col-xl-12 col-lg-12 mb-lg-5">
						   <div className="text-lg-end text-center mb-lg-5">
							  <h1 className="title text-white">About</h1>

							  <div className="border-small bg-white"></div>
						   </div>
						</div>
						<div className="col-xl-12 mb-lg-5">
						   <div className="row align-items-center mb-lg-5">
							  <div className="col-xl-2 text-center text-lg-start col-lg-3">
								 <h1 className="spotLight"><u>Spot</u><span className="text-yellow"><u>light</u></span></h1>
							  </div>
							  <div className="col-xl-10 col-lg-9">
								 <p className="text-white m-0">{ ReactHtmlParser(this.state.home_page_about_content) }</p>
							  </div>
						   </div>
						</div>
					 </div>
				  </div>
			   </section>
			   End About  */} 
			</React.Fragment>
		);
	}
}

export default Home;