import React, { Component } from 'react';
import $ from 'jquery';
import axios from 'axios';

class Influencers extends Component {
	constructor(props) {
		super(props);
		this.state = {
			api_base_url : window.$apiBaseUrl,
			image_base_url : window.$imageBaseUrl,
			influencers_list : [],
			offset : 0,
			limit : 9,
		}
		this.getInfluencersList = this.getInfluencersList.bind(this);
	}
	componentDidMount() {
		document.title = "Spotlight Management | Our Talent";
		window.scrollTo(0, 0);
		this.getInfluencersList();
	}
	getInfluencersList(){
		var limit = this.state.limit;
		var offset = this.state.offset;
		var formData = new FormData();
		//formData.append('limit',limit);
		//formData.append('offset',offset);
		axios.post(''+this.state.api_base_url+'creator/read.php', formData)
		.then(res => {
			var response = res.data.resp
			if(response === '1'){
				var influencers_obj = res.data.data;
				let {influencers_list} = this.state;
				influencers_list.push.apply(influencers_list, influencers_obj);
				this.setState({influencers_list});
				var new_offset = limit+offset;
				this.setState({ offset: new_offset });
			}
			
		}).catch(error => {
			console.log(error);
		});
	}
	render() {
		let j=1;
        let influencers_contents_desktop = this.state.influencers_list.map((influencers,j)=>{
			var facebook_link = [];
			var twitter_link = [];
			var instagram_link = [];
			if(influencers.creator_facebook_link!==""){
				facebook_link.push(
					<a href={influencers.creator_facebook_link} target="_blank"><i className="fab fa-youtube"></i></a>
				);
			}
			if(influencers.creator_twitter_link!==""){
				twitter_link.push(
					<a href={influencers.creator_twitter_link} target="_blank"><i className="fab fa-tiktok"></i></a>
				);
			}
			if(influencers.creator_instagram_link!==""){
				instagram_link.push(
					<a href={influencers.creator_instagram_link} target="_blank"><i className="fab fa-instagram"></i></a>
				);
			}
			return (
				<React.Fragment>
					<div className="col-lg-4 mb-4">
						<div className="talent-box mb-4">
							<img src={this.state.image_base_url+'creator/'+influencers.creator_image} className="img-fluid" alt="" />
							<div className="content-overlay"></div>
							<div className="talent-content fadeIn-bottom">
								<h3 className="name">{influencers.creator_name}</h3>
								<div className="social-icon mt-4">
									{facebook_link}
									{twitter_link}
									{instagram_link}
								</div>
							</div>
						</div>
					</div>
				</React.Fragment>
			)
		});
		let i=1;
        let influencers_contents_mobile = this.state.influencers_list.map((influencers,i)=>{
			var facebook_link = [];
			var twitter_link = [];
			var instagram_link = [];
			if(influencers.creator_facebook_link!==""){
				facebook_link.push(
					<a href={influencers.creator_facebook_link} target="_blank"><i className="fab fa-youtube"></i></a>
				);
			}
			if(influencers.creator_twitter_link!==""){
				twitter_link.push(
					<a href={influencers.creator_twitter_link} target="_blank"><i className="fab fa-tiktok"></i></a>
				);
			}
			if(influencers.creator_instagram_link!==""){
				instagram_link.push(
					<a href={influencers.creator_instagram_link} target="_blank"><i className="fab fa-instagram"></i></a>
				);
			}
			return (
				<React.Fragment>
					<div className="col-6 col-md-6 mb-2 pe-1">
						<div className="talent-box">
							<img src={this.state.image_base_url+'creator/'+influencers.creator_image} className="img-fluid" alt="" />
							<div className="content-overlay"></div>
							<div className="talent-content fadeIn-bottom">
								<h3 className="name">{influencers.creator_name}</h3>
								<div className="social-icon mt-4">
									{facebook_link}
									{twitter_link}
									{instagram_link}
								</div>
							</div>
						</div>
					</div>
				</React.Fragment>
			)
		});
		return (
		<React.Fragment>
			{/* Start Banner */}
			<section className="banner sub-banner">
			  <img src="img/enfluncer.jpg" className="img-fluid" alt="" />
			  <div className="overlay"></div>
			  <div className="heading">
				 <h3 className="subbanner-subtitle d-none d-lg-block">Spotlight</h3>
				 <h3 className="subbanner-subtitle d-block d-lg-none">Home.</h3>
				 <h2 className="subbanner-title">Talent</h2>
			  </div>
			</section>
			{/* End Banner */}

			{/* Our Clients */}
			<section className="">
			  <div className="container mt-lg-5">
				 <div className="row mt-lg-5">
					<div className="col-xl-12 col-lg-12 mb-lg-5">
					   <div className="text-lg-end text-center mt-lg-5 mt-lg-0 mb-md-5 mb-4">
						  <h1 className="title">MEET OUR TALENT</h1>

						  <div className="border-small"></div>
					   </div>
					</div>
				 </div>
			  </div>
			  {/* Desktop  */}
			  <div className="meet-our-talent d-none d-lg-block">
				 <div className="container">
					<div className="row">
						{influencers_contents_desktop}
					   <div className="col-lg-12">
						   {/* 
							<div className="text-center py-5">
								<a href="javascript:void(0);" className="btn-black" onClick={this.getInfluencersList}>More</a>
								<input type="hidden" name="offset" value={this.state.offset} />
							</div>
							*/}
					   </div>
					</div>

				 </div>
			  </div>
			  {/* End Desktop  */}
			  {/* Mobile  */}
			  <div className="meet-our-talent d-block d-lg-none">
				 <div className="container">
					<div className="row">

						{influencers_contents_mobile}
					   {/* 
					   <div className="col-lg-12">
						  <div className="text-center py-5">
							 <a href="javascript:void(0);" className="btn-black" onClick={this.getInfluencersList}>More</a>
						  </div>
					   </div>
					   */}
					</div>

				 </div>
			  </div>
			  {/* End Mobile */}

			</section>
			{/* End our clients */}
		</React.Fragment>
		);
	}
}

export default Influencers;