import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import MainComponent from './components/MainComponent/MainComponent';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';

{/*
	development client server basepath: /dev/spotlight/web
	main client server basepath: /spotlight
	local basepath: /
*/}

const Routes = () => (
	<div className="wrap">
		<BrowserRouter forceRefresh={false} basename="/">
				<MainComponent />
		</BrowserRouter>
	</div>
);



export default Routes;