import React, { Component } from 'react';
import $ from 'jquery';
import axios from 'axios';
import { Link, withRouter } from 'react-router-dom';

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			api_base_url : window.$apiBaseUrl,
			image_base_url : window.$imageBaseUrl,
			header_logo_desktop : '',
			header_logo_mobile : ''
		}
		this.getSettingsContent = this.getSettingsContent.bind(this);
	}
	componentDidMount() {
		this.getSettingsContent();
	}
	getSettingsContent(){
		var formData = new FormData();
		axios.post(''+this.state.api_base_url+'settings/read.php', formData)
		.then(res => {
			this.setState({header_logo_desktop : res.data.header_logo.meta_value});
			this.setState({header_logo_mobile : res.data.header_logo_mobile.meta_value});
		}).catch(error => {
			console.log(error);
		});
	}
	render() {
		return (
		<React.Fragment>
			{/* Desktop Menu */}
			<div className="d-none d-lg-block">
			  <nav id="navbar" className="desktop-menu container fixme">
				 <div className="wrapper">
					<div className="logo"><Link to="/"><img src={this.state.image_base_url+'logo/'+this.state.header_logo_desktop} className="img-fluid" alt=""/></Link>
					</div>
					<ul className="nav-links">
					   {/* <li><a href="about">About</a></li>
					   <li><a href="about">About</a></li>
					   <li><a href="influencers">Influencers</a></li>
					   <li><a href="careers">Careers</a></li>
					   <li className=""><a href="contact">Contact</a></li>*/}

					   <li><Link to="/about">About</Link></li>
					   <li><Link to="/influencers">Talent</Link></li>
					   <li><Link to="/contact">Contact</Link></li>
					</ul>
				 </div>
			  </nav>
		   </div>
		   {/* End Desktop Menu */}

		   {/* Mobile Menu */}
			<header className="hs-menubar mobile-menu d-block d-lg-none">
			  	<div className="brand-logo">
				 	<Link to="/"><img src={this.state.image_base_url+'logo/'+this.state.header_logo_mobile} className="img-fluid" alt=""/></Link>
			  	</div>
			  	<div className="menu-trigger"> <i className="zmdi zmdi-menu"></i></div>

			  	<nav className="hs-navigation">
					<ul className="nav-links">
						<li><Link to="/about">About</Link></li>
						<li><Link to="/influencers">Talent</Link></li>
						<li><Link to="/careers">Careers</Link></li>
						<li><Link to="/contact">Contact</Link></li>
					</ul>
			  	</nav>
		   	</header>
		   {/* End Mobile Menu */}
		</React.Fragment>
		);
	}
}

export default Header;