import React, { Component } from 'react';
import $ from 'jquery';
import axios from 'axios';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
var tetimonial_slider_settings ={
	dots: true,
    autoplay: false,
    infinite: true,
    slidesToShow:3,
    slideswToScroll: 3,
    arrows: true,
    responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }

    ]
};

class About extends Component {
	constructor(props) {
		super(props);
		this.state = {
			api_base_url : window.$apiBaseUrl,
			image_base_url : window.$imageBaseUrl,
			client_sliders :[],
			brand_logos :[],
			about_page_about_content : '',
			about_page_first_iamge : '',
			about_page_second_iamge : '',
		}
		this.getClientSlider = this.getClientSlider.bind(this);
		this.getBrandLogos = this.getBrandLogos.bind(this);
		this.getAboutPageAboutContent = this.getAboutPageAboutContent.bind(this);		
		this.getAboutPageAboutImage = this.getAboutPageAboutImage.bind(this);		
	}
	componentDidMount() {
		document.title = "Spotlight Management | About Us";
		window.scrollTo(0, 0);
		this.getClientSlider();
		this.getBrandLogos();
		this.getAboutPageAboutContent();
		this.getAboutPageAboutImage();
	}
	getClientSlider(){
		var formData = new FormData();
		axios.post(''+this.state.api_base_url+'testimonial/read.php', formData)
		.then(res => {
			var response = res.data.resp
			if(response === '1'){
				var slider_obj = res.data.data;
				let {client_sliders} = this.state;
				client_sliders = slider_obj;
				this.setState({client_sliders});
			}
		}).catch(error => {
			console.log(error);
		});
	}

	getBrandLogos(){
		var formData = new FormData();
		axios.post(''+this.state.api_base_url+'brands_logo/read.php', formData)
		.then(res => {
			var response = res.data.resp
			if(response === '1'){
				var slider_obj = res.data.data;
				let {brand_logos} = this.state;
				brand_logos = slider_obj;
				this.setState({brand_logos});
			}
		}).catch(error => {
			console.log(error);
		});
	}

	getAboutPageAboutContent(){
		var formData = new FormData();
		formData.append('page_type','about_page');
		axios.post(''+this.state.api_base_url+'about/read.php', formData)
		.then(res => {
			this.setState({about_page_about_content: res.data});
		}).catch(error => {
			console.log(error);
		});
	}

	getAboutPageAboutImage(){
		var formData = new FormData();
		axios.post(''+this.state.api_base_url+'about/read_image.php', formData)
		.then(res => {
			this.setState({about_page_first_iamge: res.data.first_image.page_image});
			this.setState({about_page_second_iamge: res.data.second_image.page_image});
		}).catch(error => {
			console.log(error);
		});
	}
	render() {
		let i=1;
        let client_sliders = this.state.client_sliders.map((client_slider,i)=>{
			return (
				<div className="">
					<div className="clients-box mb-lg-4">
						<div className="mb-5 mt-4">
							<h2 className="card-title">{client_slider.client_name}</h2>
							<h3 className="sub-title mt-3">{client_slider.company_name}</h3>
						</div>
						<div className="clients-content">
							<div className="d-flex">
								<div className="me-2">
								<img src="img/e1.png" className="img-fluid" alt="" />
								</div>
								<div className="">
								<p className="comment">{client_slider.testimonial_content}</p>
								</div>
								<div className="align-items-end d-flex text-end">
								<img src="img/e1.png" className="img-fluid" alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		});
		let j=1;
        let brand_logos = this.state.brand_logos.map((brand_logo,j)=>{
			return (
				<div class="col-lg-2 col-6 text-center">
					<img src={this.state.image_base_url+'brands-logo/'+brand_logo.logo_name} class="img-clint" alt=""></img>
				</div>
			)
		});
		return (
		<React.Fragment>
			{/* Start Banner */}
		   <section className="banner sub-banner">
			  <img src="img/about-banner.jpg" className="img-fluid" alt="" />
			  <div className="overlay"></div>
			  <div className="heading">
				 <h3 className="subbanner-subtitle d-none d-lg-block">Spotlight</h3>
				 <h3 className="subbanner-subtitle d-block d-lg-none">Home.</h3>
				 <h2 className="subbanner-title">About us</h2>
			  </div>
		   </section>
		   {/* End Banner */}
		   {/* About Content */}
		   {/*<section className="what-we-are">
			  <div className="text-lg-end text-center mb-4 d-block d-lg-none">
				 <h1 className="title">WHAT WE’RE ABOUT</h1>
				 <div className="border-small"></div>
			  </div>
			  <img src={this.state.image_base_url+'about/'+this.state.about_page_first_iamge} className="img-fluid m-0 d-block d-lg-none w-100" alt="" />
			  <div className="container mb-lg-5">
				 <div className="row">
					<div className="col-xl-6 mb-xl-4 mb-lg-5">
					   <div className="text-center mb-xl-0 mb-lg-5">
						  <img src={this.state.image_base_url+'about/'+this.state.about_page_second_iamge} className="img-fluid d-none d-lg-block" alt="" />
					   </div>
					</div>
					<div className="col-xl-6">
					   <div className="text-lg-end text-center d-none d-lg-block mb-md-5">
						  <h1 className="title">WHAT WE’RE ABOUT</h1>
						  <div className="border-small"></div>
					   </div>
					   <div className="mt-md-5 mt-4 text-center d-block d-lg-none">
						  <p className="text-black d-block fs-3 m-0 d-lg-none">Hi, we are</p>
						  <h1 className="spotLight text-black"><u>Spot</u><span className="text-yellow"><u>light</u></span></h1>
					   </div>
					   <div className="pt-4">
					   	{ ReactHtmlParser(this.state.about_page_about_content) }
					   </div>
					</div>


				 </div>
			  </div>

		   </section> */}
		   <section className="about about-page">
				<div className="container">
					<div className="row">
						<div className="col-lg-6 offset-lg-2 col-sm-12">
							<div className="text-lg-end text-center mb-5">
							<h1 className="title-about">
								<span className="text-yellow">Talent With</span> <span>Intent</span> <br/> 
								<span className="text-right text-dark-gray">Passion</span><br/>
								<span className="text-right text-light-gray">Difference</span>
							</h1>

								<div className="border-small"></div>
							</div>
						</div>

						<div className="col-lg-5 col-sm-12">
							<img src={this.state.image_base_url+'about/'+this.state.about_page_first_iamge} alt="" className="img-about" />
						</div>
						<div className="col-lg-7 col-sm-12 ">
							<div className="y-box">
								<h1>About Us</h1>
								{ ReactHtmlParser(this.state.about_page_about_content) }
							</div>
						</div>
					</div>
				</div>
			</section>
		   {/* End About Content */}
		   {/* Our Clients */}
		   <section className="">
			  <div className="container mt-5">
				 <div className="row mt-md-5">
					<div className="col-xl-12 col-lg-12 mb-lg-5 mb-md-5 mb-5">
					   <div className="text-lg-end text-center mt-5 mt-lg-0 mb-lg-5">
						  <h1 className="title">Testimonials</h1>

						  <div className="border-small"></div>
					   </div>
					</div>
				 </div>
			  </div>
			  <div className="testmonial">
				 <div className="container-fluid p-0">
					<div className="row">
					   <div className="col-xl-12">
						  <div className="testimonials pt-3 m-0">
							<Slider {...tetimonial_slider_settings}>
								{client_sliders}
							</Slider>
						  </div>
					   </div>
					</div>

				 </div>
			  </div>

		   </section>
		   {/* End our clients */}

		   {/* Brand */}
		   <section className="about brand mt-5">
			  	<div className="container">
				  <div class="row">
						<div class="col-xl-12 col-lg-12 mb-lg-5">
						<div class="text-lg-end text-center mt-5 mt-lg-0 mb-lg-5">
							<h1 class="title">Brands We Have Worked With</h1>

							<div class="border-small"></div>
						</div>
						</div>
					</div>
			  		<div class="row">
						<div class="col-lg-10 offset-lg-1 col-12">
							<div class="row">
								{brand_logos}
							</div>
						</div>
					</div>
			  </div>
		   </section>
		   {/* End Brand */}
		</React.Fragment>
		);
	}
}

export default About;