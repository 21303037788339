import React, { Component } from 'react';
import $ from 'jquery';
import axios from 'axios';

class Contact extends Component {
	constructor(props) {
		super(props);
		this.state = {
			api_base_url : window.$apiBaseUrl,
			image_base_url : window.$imageBaseUrl,
			name : '',
			phone : '',
			email : '',
			subject : '',
			message : '',
			contact_page_address : '',
			contact_page_phone : '',
			contact_page_email : '',
		}
		this.change = this.change.bind(this);
		this.sendContactEmail = this.sendContactEmail.bind(this);
		this.validateConatctForm = this.validateConatctForm.bind(this);
		this.getSettingsContent = this.getSettingsContent.bind(this);
	}
	componentDidMount() {
		document.title = "Spotlight Management | Contact Us";
		window.scrollTo(0, 0);
		this.getSettingsContent();
	}
	change(e) {
		this.setState({[e.target.name]: e.target.value});
	}
	getSettingsContent(){
		var formData = new FormData();
		axios.post(''+this.state.api_base_url+'settings/read.php', formData)
		.then(res => {
			this.setState({contact_page_address : res.data.contact_page_address.meta_value});
			this.setState({contact_page_phone : res.data.contact_page_phone.meta_value});
			this.setState({contact_page_email : res.data.contact_page_email.meta_value});
		}).catch(error => {
			console.log(error);
		});
	}
	sendContactEmail(){
		if(this.validateConatctForm()){
			var formData = new FormData();
			formData.append('name',this.state.name);
			formData.append('phone',this.state.phone);
			formData.append('subject',this.state.subject);
			formData.append('email',this.state.email);
			formData.append('message',this.state.message);
			axios.post(''+this.state.api_base_url+'contact/contact.php', formData)
			.then(res => {
				var response = res.data.resp
				if(response === 1){
					this.setState({ name: '' });
					this.setState({ phone: '' });
					this.setState({ subject: '' });
					this.setState({ email: '' });
					this.setState({ message: '' });
					$('.email_resp').addClass('alert-success').html(res.data.msg);
				}
			}).catch(error => {
				console.log(error);
			});
		}
	}
	validateConatctForm(){
		var valid = true;
		var name = this.state.name; 
		var phone = this.state.phone; 
		var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
		var subject = this.state.subject; 
		var email = this.state.email; 
		var message = this.state.message; 

		if(name == ""){
			$('#name').css('border','2px solid red');
			valid = false;
		}else{
			$('#name').css('border','');
		}

		if(phone == ""){
			$('#phone').css('border','2px solid red');
			valid = false;
		}else{
			$('#phone').css('border','');
		}

		if(email == ""){
			$('#email').css('border','2px solid red');
			valid = false;
		}else if(!pattern.test(email)){
			$('#email').css('border','2px solid red');
			valid = false;
		}else{
			$('#email').css('border','');
		}

		if(subject == ""){
			$('#subject').css('border','2px solid red');
			valid = false;
		}else{
			$('#subject').css('border','');
		}

		if(message == ""){
			$('#message').css('border','2px solid red');
			valid = false;
		}else{
			$('#message').css('border','');
		}

		return valid;
	}
	render() {
		return (
		<React.Fragment>
			{/* Start Banner */}
			<section className="banner mb-0 sub-banner">
			  <img src="img/contact-bg.jpg" className="img-fluid" alt="" />
			  <div className="overlay"></div>
			  <div className="heading">
				 <h3 className="subbanner-subtitle d-none d-lg-block">Spotlight</h3>
				 <h3 className="subbanner-subtitle d-block d-lg-none">Home.</h3>
				 <h2 className="subbanner-title">Contact US</h2>
			  </div>
			</section>
			{/* End Banner */}
			<section className="">
				<div className="contact">
				 <div className="container-fluid p-0">
					<div className="row">
					   <div className="d-none d-lg-block">
						  <img src="img/map.jpg" className="img-fluid" alt="" />
					   </div>
					</div>

				 </div>
				</div>
				<div className="container">
				 <div className="row pb-5">
					<div className="col-xl-12 col-lg-12 mb-lg-5">
					   <div className="bg-black black-card">
						  <div className="text-lg-end text-center mt-lg-5 mt-5 mb-5 mt-lg-0 mb-lg-5">
							 <h1 className="title text-white">Message</h1>

							 <div className="border-small bg-white"></div>
						  </div>
						  <div className="">
							 <form action="">
								<div className="row">
								   <div className="col-lg-6 col-md-6 mb-3">
									  <input type="text" name="name" id="name" className="form-control" placeholder="Name"  value={this.state.name} onChange={this.change} />
								   </div>
								   <div className="col-lg-6 col-md-6 mb-3">
									  <input type="text" name="phone" id="phone" className="form-control" placeholder="Contact No"  value={this.state.phone} onChange={this.change} />
								   </div>
								   <div className="col-lg-6 col-md-6 mb-3">
									  <input type="text" name="subject" id="subject" className="form-control" placeholder="Subject line"  value={this.state.subject} onChange={this.change} />
								   </div>
								   <div className="col-lg-6 col-md-6 mb-3">
									  <input type="text" name="email" id="email" className="form-control" placeholder="Email"  value={this.state.email} onChange={this.change} />
								   </div>
								   <div className="col-lg-12 mb-3">
									  <textarea className="form-control" id="message" name="message" rows="6" placeholder="Enter your message here.."  value={this.state.message} onChange={this.change}></textarea>
								   </div>
								   <div className="col-lg-12 mb-3 text-center">
									  <button type="button" className="btn-black btn-gray border-0" onClick={this.sendContactEmail}>Send</button>
								   </div>
								   <div className="alert email_resp"></div>
								</div>
							 </form>
						  </div>
					   </div>

					</div>
				 </div>
				</div>
			</section>
		</React.Fragment>
		);
	}
}

export default Contact;